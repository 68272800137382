@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

:root {
    --main-font: "Nominee";
    --main-text: #fff;
    scroll-behavior: smooth;
}

* {
    
    font-family: "DM Sans", sans-serif;
  

    
}
body{
    background-color: #010101 !important;
}
.border {
    border: 1px solid #ccc;
}
html {
    overflow: hidden;
    height: 100%;
    scroll-behavior: smooth;
}
.scroll-container { 
    height: 300px; 
    overflow-y: scroll; 
} 

.scroll-container::-webkit-scrollbar { 
    width: 5px; 
} 

.scroll-container::-webkit-scrollbar-track { 
    background:#111111;
} 

.scroll-container::-webkit-scrollbar-thumb { 
    background: #111111;
} 

.scroll-container::-webkit-scrollbar-thumb:hover { 
    background: #111111;
} 
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:#111111;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0f0d0d;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #010101;
  }

   

body {
    
    overflow-x: hidden;
    overflow: auto;
    height: 100%
    
   
}
.app-bg{
	background-color: #000000;
	color: #F9F9F9 !important;
    font-family: "DM Sans", sans-serif;
}
@layer {
    .col {
        @apply flex flex-col flex-wrap;
    }

    .row {
        @apply flex flex-row flex-wrap;
    }

    .center {
        @apply items-center justify-center;
    }
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
}

button {
    @apply active:scale-90 transition-transform duration-200;
}

.tweets-slider-container {
  position: relative;
  max-width: 598px;
  margin: 40px auto 80px;
  padding: 20px 80px;
  overflow: visible;
}

.tweet-slide {
  outline: none;
  padding: 20px;
}

.tweet-card {
  background: #000;
  border: 1px solid #333;
  border-radius: 16px;
  padding: 16px;
  transition: transform 0.2s ease;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.tweet-card:hover {
  transform: translateY(-2px);
}

.tweet-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.tweet-header img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.tweet-header .user-info {
  display: flex;
  flex-direction: column;
}

.tweet-header .user-info .name {
  font-weight: bold;
  color: #fff;
}

.tweet-header .user-info .username {
  color: #8899a6;
}

.tweet-content {
  color: #fff;
  margin-bottom: 12px;
}

.tweet-actions {
  display: flex;
  justify-content: space-between;
  color: #8899a6;
}

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 10;
  width: 40px !important;
  height: 40px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 50% !important;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: -60px !important;
}

.slick-next {
  right: -60px !important;
}

.slick-dots {
  position: absolute;
  bottom: -40px;
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
}

.slick-dots li.slick-active button {
  background: rgba(255, 255, 255, 0.9);
}

.round-gradient {
  position: relative;
  z-index: -1;
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(circle at top right, #292442, #1d1b27);
}

.dark .round-gradient {
  background: radial-gradient(circle at top right, #292442, #1d1b27);
}

.app-logo {
    background: linear-gradient(
        135deg,
        rgba(33, 150, 243, 1) 10%,
        rgba(218, 112, 214, 1) 30%,
        rgba(255, 255, 255, 1) 90%,
        rgba(255, 255, 255, 0.705) 2%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.text-gd-hover:hover{
   
    background: linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7);
    background-clip: text;
    color: linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7);

  background: -webkit-linear-gradient(to left,#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    color: linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7);
}
.text-gd{
   
    background: linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7);
    background-clip: text;
    color: linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7);

  background: -webkit-linear-gradient(to left,#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    color: linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7);
}
.bg-gd{
    background: linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7);
    
}
.bg-gd50{
    background: linear-gradient(to left, #9b51e079, #c655cc79, #e162ba79, #f076ad79, #f78da779);
    
}
.gradient-border {
    position: relative;
  }
  
  .gradient-border::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(to right, #f06, #aaf);
    z-index: -1;
    border-radius: inherit; /* Ensure it matches the parent element's border-radius */
  }
  
  .gradient-border > * {
    position: relative;
    z-index: 1;
  }
  html {
    scroll-behavior: smooth;
  }

  /* Global styles */
.nonselectable {
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, Safari, Firefox */
    pointer-events: none; /* Disables mouse interactions */
  }

.tweet-card {
  cursor: pointer;
  transition: transform 0.2s ease;
  text-decoration: none;
  color: inherit;
}

.tweet-card:hover {
  transform: translateY(-2px);
}

.tweet-link {
  display: block;
  text-decoration: none;
  color: inherit;
}