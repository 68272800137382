.button-gd {
    align-items: center;
  
    border: 0;
    border-radius: 3px;
    /* box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px; */
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 16px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    /* padding: 3px; */
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    transition: all .6s;

   }
   
   .button-gd:active,
   .button-gd:hover {
   
    /* background-image: linear-gradient(144deg,#F78DA7, #9B51E0 ); */
    background: linear-gradient(144deg,#F78DA7, #9B51E0 );
   }
   
   .button-gd span {
    background-color: #010101;
    padding: 14px 16px;
    /* border-radius: 3px; */
    width: 100%;
    height: 100%;
    transition: 300ms;
   }
   
   .button-gd:hover span {
    background: none;
   }
   
   .button-gd:active {
    transform: scale(0.9);
   }

   .button-gd1 {
    padding: 11px 16px;
    display: flex;
    min-width: 120px;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    background: none; /* Ensure background is transparent initially */
    transition: all 300ms ease-in-out; /* Specify background-color for transition */
    position: relative;

  }
  button-gd::before{
    content: "";
    position: absolute;
    background-color: linear-gradient(144deg, #F78DA7, #9B51E0);
    opacity: 0;
  }
  button-gd:hover>button-gd::before{
    opacity: 100%;
  }
  
  .button-gd1:hover {
    background: linear-gradient(144deg, #F78DA7, #9B51E0); /* Gradient for hover state */
  }
  