.glowMovingLB {
    position: absolute;
    animation: glowMoveL 35s infinite 0s forwards, blinker 5s infinite 0s ease-in-out;
}

@keyframes blinker {
    0% {
        opacity: 80%;
    }
    50% {
        opacity: 60%;
    }
    100%{
        opacity: 80%    ;
    }
}

@keyframes glowMoveL {
    25% {
        left: 15%;
        bottom: 10%;
    }
    50% {
        left: 22%;
        top: 7%;
    }
    75% {
        right: 2%;
        bottom: 5%;
    }
}


.glowMovingTL{
    position: absolute;
    animation: glowMoveLT 35s infinite 0s forwards, blinker 5s infinite 0s ease-in-out;
 
}

@keyframes glowMoveLT {
    0%{
        left: -29%;
        bottom:1% ;
    }
    25% {
        left: 30%;
        bottom: 0%;
    }
    50% {
        bottom: 14%;
        left: 4%;
    }
    75% {
        left: -10%;
        bottom: -14%;
    }
    100%{
      
        left: -29%;
            bottom:1% ;
        
    }
}

.moveGlowLB{
    position: absolute;
    animation: momoveGlowLB 35s infinite 0s forwards, blinker 5s infinite 0s ease-in-out;
   
}

@keyframes momoveGlowLB {
    0%{
        right: -50%;
        top: 0%
    }
    25%{
        right: 0%;
        top: 100%
    }
    50%{
        right: -100%;
        top: 25%
    }
    75%{
        right: 45%;
        top: -25%
    }
    100%{
        right: -50%;
        top: 0%
    }

}

.movePurple{
    position: absolute;
    animation: movePurple 35s infinite 0s forwards;

}

@keyframes movePurple {
    0%{
        left: -500px;
        top:-1%
       
    }
    25%{
        left: -100px;
    top:-3%
    }
    50%{
        left:600px;
        top:-7%
       
    }
    75%{
        left:-100px;
        top:-10%
    }
    100%{
        right: -50%;
        top: 0%
    }

}


.moveCyan{
    position: absolute;
    animation: moveCyan 35s infinite 0s forwards;
    left: 450px;
    top:100px;
}

@keyframes moveCyan {
    0%{
        left: 359px;
        top:-250px;
       
    }
    25%{
        left: -100px;
        top:-10px;
    }
    50%{
        left: 150px;
        top:300px;
       
    }
    75%{
        left: 450px;
        top:100px;
    }
    100%{
        left: 359px;
        top:-250px;
       
    }

}
