
.main-sec{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/public/bg.png");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}