@keyframes slide {
    from{
        transform: translateX(0%);
    }
    to{
        transform: translateX(-100%);
    }
}

.slider1{
    white-space: no-wrap;
    animation:55s slide infinite linear;
    width: 100%; 
}
.container1:hover .slider1{ 
    cursor: pointer;
    animation-play-state: paused;
}