@keyframes running-logos {
    from{
        transform: translateX(100%);
    }
    to{
        transform: translateX(-100%);
    }
}

.slider-faq{
    min-width: fit-content;
    animation: running-logos infinite linear 10s none ;
}
.slider-faq:nth-child(2) {
    animation-delay: 5s; /* Adjust the delay as needed */
}


.slider-container-faq::before{
    position: absolute;
    top: 0;
    left: 0;    
    width: 120px;
    height: fill;
    content: "";
    z-index: 2;
    /* background: red; */
    background: linear-gradient(to left, transparent,#010101 );
}
.slider-container-faq::after{
    position: absolute;
    top: 0;
    right: 0;    
    width: 120px;
    height: fill;
    content: "";
    z-index: 2;
    /* background: red; */
    background: linear-gradient(to right, transparent,#010101 );
}
