.card1 {
    position: relative;
    overflow: hidden; /* Ensure the background image doesn't overflow */
}

.card1::after {
    content: "";
    position: absolute;
    background-image: url("../../Assests/card-bg.svg");
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    top: 0; /* Make sure the pseudo-element covers the entire card */
    width: 100%;
    opacity: 0.5; /* Adjust this value to set the desired opacity */
}
